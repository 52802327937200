<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12">
        <HeroAlt :heroAlt="heroAlt"/>
        <v-container>
          <v-row class="py-16">
            <v-col cols="12">
              <h2
                class="text-h4 text-md-h3 text-center font-weight-black text-capitalize"
              >
                Kim jestśmy?
              </h2>
              <h3
                class="text-h4 text-uppercase font-weight-thin text-center my-8"
              >
                 AI Synesthesia Sp. z o.o. jest firmą tworzoną przez zespół posiadający doświadczenie w inkubacji pomysłów od fazy zalążkowej przez rozwój start-up’u, wdrażanie pracy aż po fazę ostateczną czyli obsługę gotowego pomysłu. 
              </h3>
            </v-col>
              </v-row>
        </v-container>
            <v-row no-gutters class="flex-row-reverse">
              <v-col cols="12" md="6" align-self="center">
                  <v-img
              max-height="800"
              src="../assets/about_us_9.jpg"
              lazy-src="../assets/about_us_9.jpg"
            >
            </v-img>
            </v-col>
          <v-col cols="12" md="6" align-self="center">
              <blockquote class="text-h4 pa-5 my-4 font-weight-thin">
              Nasz zespół badawczy ma bogate doświadczenie w prowadzeniu prac badawczych w zakresie sztucznej inteligencji i Big Data oraz budowy sieci neuronowych oraz projektowaniu i realizacji dużych systemów informatycznych związanych z DataMining i Business Intelligence.
              </blockquote>
            </v-col>
            
            </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" align-self="center">
            <v-img
              max-height="800"
              src="../assets/about_us_2.jpg"
              lazy-src="../assets/about_us_2.jpg"
            >
            </v-img>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <div class="pa-lg-16 pa-md-10 px-4 py-16">
              <p>
              Spółka AI Synesthesia została powołana w celu realizacji projektu badawczo-rozwojowego, realizowanego w konsorcjum z Instytutem Centrum Zdrowia Matki Polki w Łodzi dofinansowanego z Funduszy Krajowych pt.
              </p>
              <blockquote class="text-h4 pa-5 my-4 font-weight-thin">
              „Ocena zmian w miąższu płucnym występujących u pacjentów po przebytej infekcji COVID-19 i ich wpływ na krążenie płucne - badanie przy użyciu autorskiego, hybrydowego protokołu opartego o RM, USG i algorytmy sztucznej inteligencji.”
              </blockquote>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="flex-row-reverse">
          <v-col cols="12" md="6" align-self="center">
            <v-img
              max-height="800"
              src="../assets/about_us_7.jpg"
              lazy-src="../assets/about_us_7.jpg"
            >
            </v-img>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <div class="pa-lg-16 pa-md-10 px-4 py-16">
              <h2 class="text-h3 text-center font-weight-black"></h2>
              <p>Celem projektu jest stworzenie oprogramowania, które wykorzystując sztuczną inteligencję, będzie rozpoznawać zmiany w płucach i powiązanie z nimi powikłania kardiologiczne po infekcji COVID-19. 
</p>
              <p>
              Jego działanie będzie oparte o autorski protokół badania COVID-FAST, czyli szybką (ok. 10 min) ocenę powikłań w RM płuc i serca. Dodatkowo, celem weryfikacji podejrzanych zmian, na obrazy uzyskane z RM będą nakładane obrazy z USG serca (echo). Algorytmy sztucznej inteligencji nie tylko wskażą patologię i ocenią ich wpływ na przeciążenie naczyniowego łożyska płucnego, ale również pozwolą oszacować ich wpływ na funkcję płuc.
              </p>
              <p>
                Ponadto członkowie zespołu AI Synesthesia mają doświadczenie w realizacji zakończonego sukcesem projektu “MediPlanner-platforma wspierająca pracę lekarzy w rozpoznaniu i leczeniu chorób nadciśnienia tętniczego i zaburzeń lipidowych z wykorzystaniem algorytmu predykcyjnego do generowania diagnozy, planu leczenia oraz monitorowania stanu pacjenta” realiz. wspólnie przez spółkę SAPIhub i ICZMP

              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
     <v-row no-gutters>
          <v-col cols="12" md="6" align-self="center">
            <v-img
              max-height="800"
              src="../assets/about_us_5.jpg"
              lazy-src="../assets/about_us_5.jpg"
            >
            </v-img>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <div class="pa-lg-16 pa-md-10 px-4 py-16">
              <p>
              Spółka AI Synesthesia została powołana w celu realizacji projektu badawczo-rozwojowego, realizowanego w konsorcjum z Instytutem Centrum Zdrowia Matki Polki w Łodzi dofinansowanego z Funduszy Krajowych pt.
              </p>
              <blockquote class="text-h4 pa-5 my-4 font-weight-thin">
              „Ocena zmian w miąższu płucnym występujących u pacjentów po przebytej infekcji COVID-19 i ich wpływ na krążenie płucne - badanie przy użyciu autorskiego, hybrydowego protokołu opartego o RM, USG i algorytmy sztucznej inteligencji.”
              </blockquote>
              <p>Celem projektu jest stworzenie oprogramowania, które wykorzystując sztuczną inteligencję, będzie rozpoznawać zmiany w płucach i powiązanie z nimi powikłania kardiologiczne po infekcji COVID-19. 
</p>
            </div>
          </v-col>
        </v-row>
  </section>
</template>

<script>
import HeroAlt from "../components/HeroAlt.vue"
import about_us_1 from "../assets/about_us_1.jpg"
export default {
  components: {
    HeroAlt
  },
  data() {
    return {
      heroAlt: [
        {
          src: about_us_1,
          heading: ' O nas ',
        },
      ],
    }
  },
}
</script>

