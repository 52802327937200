<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12">
        <HeroAlt :hero-alt="heroAlt" />
        <v-container>
          <v-row class="py-16">
            <v-col md="5" cols="12">
              <div class="text-h6 font-weight-bold mb-5">Biuro</div>
              <v-list two-line class="transparent">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-map-marker </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Adres</v-list-item-title>
                    <v-list-item-subtitle
                      >ul. Gdańska 91/93 lok. 35, 90-613 Łódź</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-email </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Email</v-list-item-title>
                    
                    <v-list-item-subtitle
                      ><a class='link' href='m&#97;ilt&#111;&#58;&#37;62%69uro&#64;a%69&#115;yne%7&#51;t&#37;&#54;8e%73ia&#46;p&#108;'>&#98;&#105;uro&#64;aisynesth&#101;s&#105;&#97;&#46;p&#108;</a></v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-phone </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Telefon</v-list-item-title>
                    <v-list-item-subtitle>+48 660 724 261</v-list-item-subtitle>
                   
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              
              <v-list two-line class="transparent">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-clock </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-uppercase">Poniedziałek - Piątek </v-list-item-title>
                    <v-list-item-subtitle>9.00 - 17.00</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

              </v-list>
            </v-col>
            <v-col md="7" cols="12">
              <div class="text-h4 font-weight-black mb-8">Skontaktuj się z nami!</div>
              <p class="mb-10">
              
              </p>
              <ContactForm />
            </v-col>
          </v-row>
        </v-container>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2469.2284396921164!2d19.447068715502024!3d51.76543087967832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471a352b5a8e4543%3A0x74b3088c1389ca8d!2zR2RhxYRza2EgOTEvOTMsIDkwLTYxMyDFgcOzZMW6!5e0!3m2!1spl!2spl!4v1651229159400!5m2!1spl!2spl"
          width="100%"
          height="600"
          frameborder="0"
          style="border: 0; display: block"
          allowfullscreen="true"
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import ContactForm from '../components/ContactForm.vue'
import HeroAlt from '../components/HeroAlt.vue'
import contact from "../assets/contact.jpg"
export default {
  components: {
    ContactForm,
    HeroAlt
  },
  data() {
    return {
      heroAlt: [
        {
          src: contact,
          heading: ' Kontakt',
        },
      ],
    }
  },
}
</script>

<style scoped>
#footer-top {
  display: none !important;
}
</style>
