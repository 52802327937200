<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12">
        <HeroAlt :hero-alt="heroAlt" />
        <v-container>
          <v-card class="mx-auto pa-8 mb-8" max-width="800" elevation="2">
            <v-card-title class="pb-6"
              >Wyniki postępowań konkursowych</v-card-title
            >
            <v-card-subtitle>
              Wyniki postępowań konkursowych na stanowiska:
              <strong>Tester bugfix </strong>
              oraz
              <strong
                >Pracownik naukowy o profilu IT, programista rozwiązań
                algorytmicznych
              </strong>
            </v-card-subtitle>
            <v-card-text
              >Informacje na temat wyników znajdują się w poniższych
              dokumentach:</v-card-text
            >
            <v-card-text
              ><a
                href="../data/Wyniki_postępowania_Tester_bugfix_2_20231229.pdf"
                target="_blank"
                ><strong
                  ><v-btn class="ma-2" color="indigo" dark
                    ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                  >Tester bugfix 2 29.12.2023</strong
                >
              </a></v-card-text
            >

            <v-card-text
              ><a
                href="../data/Wyniki_postępowania_Pracownik_naukowy_o_profilu_IT_programista_rozwiązań_algorytmicznych_20231229.pdf"
                target="_blank"
                ><strong
                  ><v-btn class="ma-2" color="indigo" dark
                    ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                  >Pracownik naukowy o profilu IT, programista rozwiązań
                  algorytmicznych 29.12.2023</strong
                >
              </a></v-card-text
            >
          </v-card>

          <v-card class="mx-auto pa-8 mb-8" max-width="800" elevation="2">
            <v-card-title class="pb-6"
              >Wyniki postępowań konkursowych</v-card-title
            >
            <v-card-subtitle>
              Wyniki postępowań konkursowych na stanowiska:
              <strong>Tester bugfix </strong>
            </v-card-subtitle>
            <v-card-text
              >Informacje na temat wyników znajdują się w poniższych
              dokumentach:</v-card-text
            >
            <v-card-text
              ><a
                href="../data/Wyniki_postępowania_Tester_bugfix_1_20231228.pdf"
                target="_blank"
                ><strong
                  ><v-btn class="ma-2" color="indigo" dark
                    ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                  >Tester bugfix 1 28.12.2023</strong
                >
              </a></v-card-text
            >
          </v-card>

          <v-card class="mx-auto pa-8 mb-8" max-width="800" elevation="2">
            <v-card-title class="pb-6"
              >Wyniki postępowań konkursowych</v-card-title
            >
            <v-card-subtitle>
              Wyniki postępowań konkursowych na stanowiska:
              <strong
                >Pracownik ds. przygotowania i normalizacji metadanych obrazów
                RM płuc
              </strong>
            </v-card-subtitle>
            <v-card-text
              >Informacje na temat wyników znajdują się w poniższych
              dokumentach:</v-card-text
            >
            <v-card-text
              ><a
                href="../data/Wyniki_postępowania_Pracownik_ds._przygotowania_i_normalizacji_metadanych_obrazów_RM_płuc_3__20231228.pdf"
                target="_blank"
                ><strong
                  ><v-btn class="ma-2" color="indigo" dark
                    ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                  >Pracownik ds. przygotowania i normalizacji metadanych obrazów
                  3 28122023 RM płuc</strong
                >
              </a></v-card-text
            >
            <v-card-text
              ><a
                href="../data/Wyniki_postępowania_Pracownik_ds._przygotowania_i_normalizacji_metadanych_obrazów_RM_płuc_2_20231228.pdf"
                target="_blank"
                ><strong
                  ><v-btn class="ma-2" color="indigo" dark
                    ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                  >Pracownik ds. przygotowania i normalizacji metadanych obrazów
                  2 28122023 RM płuc</strong
                >
              </a></v-card-text
            >
            <v-card-text
              ><a
                href="../data/Wyniki_postępowania_Pracownik_ds._przygotowania_i_normalizacji_metadanych_obrazów_RM_płuc__20231228.pdf"
                target="_blank"
                ><strong
                  ><v-btn class="ma-2" color="indigo" dark
                    ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                  >Pracownik ds. przygotowania i normalizacji metadanych obrazów
                  28122023 RM płuc</strong
                >
              </a></v-card-text
            >
          </v-card>

          <v-card class="mx-auto pa-8 mb-8" max-width="800" elevation="2">
            <v-card-title>Zapytanie ofertowe</v-card-title>
            <v-card-subtitle>
              W związku z realizacją przez
              <strong> AI SYNESTHESIA Sp. z o.o. </strong> Projektu p.n. „COVID
              FAST: Ocena zmian w miąższu płucnym występujących u pacjentów po
              przebytej infekcji COVID-19 i ich wpływ na krążenie płucne -
              badanie przy użyciu autorskiego, hybrydowego protokołu opartego o
              RM, USG i algorytmy sztucznej inteligencji.” finansowanego ze
              środków Narodowego Centrum Badań i Rozwoju w ramach Strategicznego
              Programu Badań Naukowych i Prac Rozwojowych „Zaawansowane
              technologie informacyjne, telekomunikacyjne i mechatroniczne” –
              INFOSTRATEG zapraszamy do złożenia oferty na stanowisko:
              <br /><br /><strong> Testera bugfix</strong>
            </v-card-subtitle>
            <v-card-text
              >Informacje na temat szczegółów zapytania oraz sposobu składania
              ofert znajdują się w dokumencie:</v-card-text
            >
            <v-card-text>
              <a
                href="../data/COVIDFAST_zapytanie_tester_bug_fix2_20231212.pdf"
                target="_blank"
              >
                <v-btn class="ma-2" color="indigo" dark
                  ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                ><strong>Tester bugfix 2</strong>
              </a></v-card-text
            >
          </v-card>

          <v-card class="mx-auto pa-8 mb-8" max-width="800" elevation="2">
            <v-card-title>Zapytanie ofertowe</v-card-title>
            <v-card-subtitle>
              W związku z realizacją przez
              <strong> AI SYNESTHESIA Sp. z o.o. </strong> Projektu p.n. „COVID
              FAST: Ocena zmian w miąższu płucnym występujących u pacjentów po
              przebytej infekcji COVID-19 i ich wpływ na krążenie płucne -
              badanie przy użyciu autorskiego, hybrydowego protokołu opartego o
              RM, USG i algorytmy sztucznej inteligencji.” finansowanego ze
              środków Narodowego Centrum Badań i Rozwoju w ramach Strategicznego
              Programu Badań Naukowych i Prac Rozwojowych „Zaawansowane
              technologie informacyjne, telekomunikacyjne i mechatroniczne” –
              INFOSTRATEG zapraszamy do złożenia oferty na stanowisko:
              <br /><br /><strong> Testera bugfix</strong>
            </v-card-subtitle>
            <v-card-text
              >Informacje na temat szczegółów zapytania oraz sposobu składania
              ofert znajdują się w dokumencie:</v-card-text
            >
            <v-card-text>
              <a
                href="../data/COVIDFAST_zapytanie_tester_bug_fix1_20231211.pdf"
                target="_blank"
              >
                <v-btn class="ma-2" color="indigo" dark
                  ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                ><strong>Tester bugfix 1</strong>
              </a></v-card-text
            >
          </v-card>

          <v-card class="mx-auto pa-8 mb-8" max-width="800" elevation="2">
            <v-card-title>Zapytanie ofertowe</v-card-title>
            <v-card-subtitle>
              W związku z realizacją przez
              <strong> AI SYNESTHESIA Sp. z o.o. </strong> Projektu p.n. „COVID
              FAST: Ocena zmian w miąższu płucnym występujących u pacjentów po
              przebytej infekcji COVID-19 i ich wpływ na krążenie płucne -
              badanie przy użyciu autorskiego, hybrydowego protokołu opartego o
              RM, USG i algorytmy sztucznej inteligencji.” finansowanego ze
              środków Narodowego Centrum Badań i Rozwoju w ramach Strategicznego
              Programu Badań Naukowych i Prac Rozwojowych „Zaawansowane
              technologie informacyjne, telekomunikacyjne i mechatroniczne” –
              INFOSTRATEG zapraszamy do złożenia oferty na stanowisko:
              <br /><br /><strong
                >Pracownika naukowego o profilu IT, programisty rozwiązań
                algorytmicznych</strong
              >
            </v-card-subtitle>
            <v-card-text
              >Informacje na temat szczegółów zapytania oraz sposobu składania
              ofert znajdują się w dokumencie:</v-card-text
            >
            <v-card-text>
              <a
                href="../data/COVIDFAST_zapytanie_programista_rozwiązań_algorytmicznych_20231211.pdf"
                target="_blank"
              >
                <v-btn class="ma-2" color="indigo" dark
                  ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                ><strong
                  >Zapytanie Pracownik naukowegy o profilu IT, programista
                  rozwiązań algorytmicznych</strong
                >
              </a></v-card-text
            >
          </v-card>

          <v-card class="mx-auto pa-8 mb-8" max-width="800" elevation="2">
            <v-card-title>Zapytanie ofertowe</v-card-title>
            <v-card-subtitle>
              W związku z realizacją przez
              <strong> AI SYNESTHESIA Sp. z o.o. </strong> Projektu p.n. „COVID
              FAST: Ocena zmian w miąższu płucnym występujących u pacjentów po
              przebytej infekcji COVID-19 i ich wpływ na krążenie płucne -
              badanie przy użyciu autorskiego, hybrydowego protokołu opartego o
              RM, USG i algorytmy sztucznej inteligencji.” finansowanego ze
              środków Narodowego Centrum Badań i Rozwoju w ramach Strategicznego
              Programu Badań Naukowych i Prac Rozwojowych „Zaawansowane
              technologie informacyjne, telekomunikacyjne i mechatroniczne” –
              INFOSTRATEG zapraszamy do złożenia oferty na stanowisko:
              <br /><br /><strong
                >Pracownika ds. przygotowania i normalizacji metadanych obrazów
                RM płuc 3</strong
              >
            </v-card-subtitle>
            <v-card-text
              >Informacje na temat szczegółów zapytania oraz sposobu składania
              ofert znajdują się w dokumencie:</v-card-text
            >
            <v-card-text>
              <a
                href="../data/COVID_FAST_zapytanie_przygotowanie_i_normalizacja_metadanych_RM_płuc_20231210_stanowisko_nr_3.pdf"
                target="_blank"
              >
                <v-btn class="ma-2" color="indigo" dark
                  ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                ><strong
                  >Zapytanie Pracownik ds. przygotowania i normalizacji
                  metadanych obrazów RM płuc 3</strong
                >
              </a></v-card-text
            >
          </v-card>

          <v-card class="mx-auto pa-8 mb-8" max-width="800" elevation="2">
            <v-card-title>Zapytanie ofertowe</v-card-title>
            <v-card-subtitle>
              W związku z realizacją przez
              <strong> AI SYNESTHESIA Sp. z o.o. </strong> Projektu p.n. „COVID
              FAST: Ocena zmian w miąższu płucnym występujących u pacjentów po
              przebytej infekcji COVID-19 i ich wpływ na krążenie płucne -
              badanie przy użyciu autorskiego, hybrydowego protokołu opartego o
              RM, USG i algorytmy sztucznej inteligencji.” finansowanego ze
              środków Narodowego Centrum Badań i Rozwoju w ramach Strategicznego
              Programu Badań Naukowych i Prac Rozwojowych „Zaawansowane
              technologie informacyjne, telekomunikacyjne i mechatroniczne” –
              INFOSTRATEG zapraszamy do złożenia oferty na stanowisko:
              <br /><br /><strong
                >Pracownika ds. przygotowania i normalizacji metadanych obrazów
                RM płuc 2</strong
              >
            </v-card-subtitle>
            <v-card-text
              >Informacje na temat szczegółów zapytania oraz sposobu składania
              ofert znajdują się w dokumencie:</v-card-text
            >
            <v-card-text>
              <a
                href="../data/COVID_FAST_zapytanie_przygotowanie_i_normalizacja_metadanych_RM_płuc_20231210_stanowisko_nr_2.pdf"
                target="_blank"
              >
                <v-btn class="ma-2" color="indigo" dark
                  ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                ><strong
                  >Zapytanie Pracownik ds. przygotowania i normalizacji
                  metadanych obrazów RM płuc 2</strong
                >
              </a></v-card-text
            >
          </v-card>

          <v-card class="mx-auto pa-8 mb-8" max-width="800" elevation="2">
            <v-card-title>Zapytanie ofertowe</v-card-title>
            <v-card-subtitle>
              W związku z realizacją przez
              <strong> AI SYNESTHESIA Sp. z o.o. </strong> Projektu p.n. „COVID
              FAST: Ocena zmian w miąższu płucnym występujących u pacjentów po
              przebytej infekcji COVID-19 i ich wpływ na krążenie płucne -
              badanie przy użyciu autorskiego, hybrydowego protokołu opartego o
              RM, USG i algorytmy sztucznej inteligencji.” finansowanego ze
              środków Narodowego Centrum Badań i Rozwoju w ramach Strategicznego
              Programu Badań Naukowych i Prac Rozwojowych „Zaawansowane
              technologie informacyjne, telekomunikacyjne i mechatroniczne” –
              INFOSTRATEG zapraszamy do złożenia oferty na stanowisko:
              <br /><br /><strong
                >Pracownika ds. przygotowania i normalizacji metadanych złożeń
                obrazów RM i USG</strong
              >
            </v-card-subtitle>
            <v-card-text
              >Informacje na temat szczegółów zapytania oraz sposobu składania
              ofert znajdują się w dokumencie:</v-card-text
            >
            <v-card-text>
              <a
                href="../data/COVID_FAST_zapytanie_przygotowanie_i_normalizacja_metadanych_złożeń_obrazów RM_i_USG_20231210.pdf"
                target="_blank"
              >
                <v-btn class="ma-2" color="indigo" dark
                  ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                ><strong
                  >Zapytanie Pracownik ds. przygotowania i normalizacji
                  metadanych złożeń obrazów RM i USG</strong
                >
              </a></v-card-text
            >
          </v-card>

          <v-card class="mx-auto pa-8 mb-8" max-width="800" elevation="2">
            <v-card-title>Zapytanie ofertowe</v-card-title>
            <v-card-subtitle>
              W związku z realizacją przez
              <strong> AI SYNESTHESIA Sp. z o.o. </strong> Projektu p.n. „COVID
              FAST: Ocena zmian w miąższu płucnym występujących u pacjentów po
              przebytej infekcji COVID-19 i ich wpływ na krążenie płucne -
              badanie przy użyciu autorskiego, hybrydowego protokołu opartego o
              RM, USG i algorytmy sztucznej inteligencji.” finansowanego ze
              środków Narodowego Centrum Badań i Rozwoju w ramach Strategicznego
              Programu Badań Naukowych i Prac Rozwojowych „Zaawansowane
              technologie informacyjne, telekomunikacyjne i mechatroniczne” –
              INFOSTRATEG zapraszamy do złożenia oferty na stanowisko:
              <br /><br /><strong
                >Pracownika ds. przygotowania i normalizacji metadanych obrazów
                RM płuc</strong
              >
            </v-card-subtitle>
            <v-card-text
              >Informacje na temat szczegółów zapytania oraz sposobu składania
              ofert znajdują się w dokumencie:</v-card-text
            >
            <v-card-text>
              <a
                href="../data/COVID_FAST_zapytanie_przygotowanie_i_normalizacja_metadanych_RM_płuc_20231210.pdf"
                target="_blank"
              >
                <v-btn class="ma-2" color="indigo" dark
                  ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                ><strong
                  >Zapytanie Pracownik ds. przygotowania i normalizacji
                  metadanych obrazów RM płuc</strong
                >
              </a></v-card-text
            >
          </v-card>

          <v-card class="mx-auto pa-8 mb-8" max-width="800" elevation="2">
            <v-card-title class="pb-6"
              >Wyniki postępowań konkursowych</v-card-title
            >
            <v-card-subtitle>
              Wyniki postępowań konkursowych na stanowiska:
              <strong>Eksperta ds. metodologii badawczych </strong>
            </v-card-subtitle>
            <v-card-text
              >Informacje na temat wyników znajdują się w poniższym
              dokumentach:</v-card-text
            >
            <v-card-text
              ><a
                href="../data/Wyniki_postepowania_Ekspert_ds_metodologii_20220920.pdf"
                target="_blank"
                ><strong
                  ><v-btn class="ma-2" color="indigo" dark
                    ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                  >
                  Wyniki postępowania Eksperta ds. metodologii
                  badawczych</strong
                >
              </a></v-card-text
            >
          </v-card>

          <v-card class="mx-auto pa-8 mb-8" max-width="800" elevation="2">
            <v-card-title>Zapytanie ofertowe</v-card-title>
            <v-card-subtitle>
              W związku z realizacją przez
              <strong> AI SYNESTHESIA Sp. z o.o. </strong> Projektu p.n. „COVID
              FAST: Ocena zmian w miąższu płucnym występujących u pacjentów po
              przebytej infekcji COVID-19 i ich wpływ na krążenie płucne -
              badanie przy użyciu autorskiego, hybrydowego protokołu opartego o
              RM, USG i algorytmy sztucznej inteligencji.” finansowanego ze
              środków Narodowego Centrum Badań i Rozwoju w ramach Strategicznego
              Programu Badań Naukowych i Prac Rozwojowych „Zaawansowane
              technologie informacyjne, telekomunikacyjne i mechatroniczne” –
              INFOSTRATEG zapraszamy do złożenia oferty na stanowisko:
              <br /><br /><strong>Eksperta ds. metodologii badawczych.</strong>
            </v-card-subtitle>
            <v-card-text
              >Informacje na temat szczegółów zapytania oraz sposobu składania
              ofert znajdują się w dokumencie:</v-card-text
            >
            <v-card-text>
              <a
                href="../data/AI_Synesthesia_zapytanie_ekspert_ds._metodologii_20221018.pdf"
                target="_blank"
              >
                <v-btn class="ma-2" color="indigo" dark
                  ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                ><strong>Zapytanie Ekspert ds. metodologii badawczych</strong>
              </a></v-card-text
            >
          </v-card>

          <v-card class="mx-auto pa-8 mb-8" max-width="800" elevation="2">
            <v-card-title class="pb-6"
              >Wyniki postępowań konkursowych</v-card-title
            >
            <v-card-subtitle>
              Wyniki postępowań konkursowych na stanowiska:
              <strong
                >Pracownik ds. przygotowania i normalizacji metadanych obrazów
                RM płuc
              </strong>
              oraz
              <strong>
                Pracownik ds. przygotowania i normalizacji metadanych wyników
                laboratoryjnych</strong
              >.
            </v-card-subtitle>
            <v-card-text
              >Informacje na temat wyników znajdują się w poniższych
              dokumentach:</v-card-text
            >
            <v-card-text
              ><a
                href="../data/Wyniki_postepowania_Pracownik_ds_przygotowania_i_normalizacji_metadanych_obrazów_RM_pluc_20220920.pdf"
                target="_blank"
                ><strong
                  ><v-btn class="ma-2" color="indigo" dark
                    ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                  >Pracownik ds. przygotowania i normalizacji metadanych obrazów
                  RM płuc</strong
                >
              </a></v-card-text
            >

            <v-card-text
              ><a
                href="../data/Wyniki_postepowania_Pracownik_ds_normalizacji_wynikow_laboratoryjnych_20220920.pdf"
                target="_blank"
                ><strong
                  ><v-btn class="ma-2" color="indigo" dark
                    ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                  >
                  Pracownik ds. przygotowania i normalizacji metadanych wyników
                  laboratoryjnych</strong
                >
              </a></v-card-text
            >
          </v-card>

          <v-card class="mx-auto pa-8 mb-8" max-width="800" elevation="2">
            <v-card-title>Zapytanie ofertowe</v-card-title>
            <v-card-subtitle>
              W związku z realizacją przez
              <strong> AI SYNESTHESIA Sp. z o.o.</strong>
              Projektu p.n. „COVID FAST: Ocena zmian w miąższu płucnym
              występujących u pacjentów po przebytej infekcji COVID-19 i ich
              wpływ na krążenie płucne - badanie przy użyciu autorskiego,
              hybrydowego protokołu opartego o RM, USG i algorytmy sztucznej
              inteligencji.” finansowanego ze środków Narodowego Centrum Badań i
              Rozwoju w ramach Strategicznego Programu Badań Naukowych i Prac
              Rozwojowych „Zaawansowane technologie informacyjne,
              telekomunikacyjne i mechatroniczne” – INFOSTRATEG zapraszamy do
              złożenia oferty na stanowiska:
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>-</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle
                      >Pracownika ds. przygotowania i normalizacji metadanych
                      obrazów RM płuc</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle
                      >Pracownika ds. przygotowania i normalizacji metadanych
                      wyników laboratoryjnych</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-subtitle>
            <v-card-text
              >Informacje na temat szczegółów zapytania oraz sposobu składania
              ofert znajdują się w dokumentach:</v-card-text
            >
            <v-card-text>
              <a
                href="../data/COVID_FAST_zapytanie_przygotowanie_i_normalizacja metadanych_RM_płuc_20220906.pdf"
                target="_blank"
              >
                <v-btn class="ma-2" color="indigo" dark
                  ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                ><strong
                  >Zapytanie przygotowanie i normalizacja metadanych obrazów RM
                  płuc - 06.09.2022</strong
                >
              </a>
            </v-card-text>

            <v-card-text>
              <a
                href="../data/COVID_FAST_zapytanie_pracownik_ds._przygotowania_i_normalizacji_metadanych_wyników_laboratoryjnych_20220906.pdf"
                target="_blank"
              >
                <v-btn class="ma-2" color="indigo" dark
                  ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                ><strong
                  >Zapytanie przygotowanie i normalizacja metadanych wyników
                  laboratoryjnych - 06.09.2022</strong
                >
              </a>
            </v-card-text>
          </v-card>

          <v-card class="mx-auto pa-8 mb-8" max-width="800" elevation="2">
            <v-card-title
              >Zapytanie ofertowe w trybie rozeznania rynku</v-card-title
            >
            <v-card-subtitle>
              W imieniu Spółki <strong> AI SYNESTHESIA Sp. z o.o. </strong> z
              siedzibą w Łodzi, ul Gdańska 91/93 lok. 35, aplikującej o
              dofinansowanie w ramach Strategicznego Programu Badań Naukowych i
              Prac Rozwojowych „Zaawansowane technologie informacyjne,
              telekomunikacyjne i mechatroniczne” - INFOSTRATEG IV na realizację
              projektu pt.
              <strong>
                „VerbatiMed.AI - inteligentny system rozpoznawania mowy i
                asystent głosowy dla lekarzy z zastosowaniem sztucznej
                inteligencji i uczenia maszynowego” </strong
              >, w związku z planowanym ogłoszeniem postępowania na wybór
              wykonawcy usługi badawczej w ramach realizacji Projektu,
              zapraszamy Państwa do złożenia ofert szacunkowych na realizację
              prac badawczych na następujących stanowiskach:
            </v-card-subtitle>
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item v-for="(item, i) in items" :key="i">
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title
                    ><v-list-item-subtitle
                      v-text="item.subtitle"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-card-text>
              <a
                href="../data/VerbatiMed.AI_ogłoszenie_rozeznanie_rynku.pdf"
                target="_blank"
              >
                <v-btn class="ma-2" color="indigo" dark
                  ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                ><strong
                  >VerbatiMed.AI zapytanie ofertowe w trybie rozeznania
                  rynku</strong
                >
              </a>
            </v-card-text>
            <v-card-text>
              <a
                href="../data/Verbatimed.AI_załączniki_nr_1-13_do_zapytania_w_trybie_rozeznania_rynku.pdf"
                target="_blank"
              >
                <v-btn class="ma-2" color="indigo" dark
                  ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                ><strong
                  >Verbatimed.AI załączniki nr 1-13 do zapytania w trybie
                  rozeznania rynku</strong
                >
              </a>
            </v-card-text>
          </v-card>

          <v-card class="mx-auto pa-8 mb-8" max-width="800" elevation="2">
            <v-card-title class="pb-6"
              >Wyniki postępowań konkursowych</v-card-title
            >
            <v-card-subtitle>
              Wyniki postępowań konkursowych na stanowiska:
              <strong>Programista rozwiązań algorytmicznych</strong>
            </v-card-subtitle>
            <v-card-text
              >Informacje na temat wyników znajdują się w poniższych
              dokumentach:</v-card-text
            >
            <v-card-text
              ><a
                href="../data/Wyniki_postepowania_programista_rozwiazan_Algo_20220712.pdf"
                target="_blank"
                ><strong
                  ><v-btn class="ma-2" color="indigo" dark
                    ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                  >
                  Wyniki postępowania Eksperta ds. metodologii
                  badawczych</strong
                >
              </a></v-card-text
            >
          </v-card>

          <v-card class="mx-auto pa-8 mb-8" max-width="800" elevation="2">
            <v-card-title>Zapytanie ofertowe</v-card-title>
            <v-card-subtitle>
              W związku z realizacją przez
              <strong> AI SYNESTHESIA Sp. z o.o. </strong> Projektu p.n. „COVID
              FAST: Ocena zmian w miąższu płucnym występujących u pacjentów po
              przebytej infekcji COVID-19 i ich wpływ na krążenie płucne -
              badanie przy użyciu autorskiego, hybrydowego protokołu opartego o
              RM, USG i algorytmy sztucznej inteligencji.” finansowanego ze
              środków Narodowego Centrum Badań i Rozwoju w ramach Strategicznego
              Programu Badań Naukowych i Prac Rozwojowych „Zaawansowane
              technologie informacyjne, telekomunikacyjne i mechatroniczne” –
              INFOSTRATEG zapraszamy do złożenia oferty na stanowisko:
              Programista rozwiązań algorytmicznych.
            </v-card-subtitle>
            <v-card-text
              >Informacje na temat szczegółów zapytania oraz sposobu składania
              ofert znajdują się w dokumencie:</v-card-text
            >
            <v-card-text>
              <a
                href="../data/Covid_Fast_zapytanie_programista_rozwiazan_algorytmicznych_20220629.pdf"
                target="_blank"
              >
                <v-btn class="ma-2" color="indigo" dark
                  ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                ><strong
                  >Zapytanie Programista rozwiązań algorytmicznych</strong
                >
              </a></v-card-text
            >
          </v-card>
          <v-card class="mx-auto pa-8 mb-8" max-width="800" elevation="2">
            <v-card-title class="pb-6"
              >Wyniki postępowań konkursowych</v-card-title
            >
            <v-card-subtitle>
              Wyniki postępowań konkursowych na stanowiska:
              <strong
                >Pracownik ds. przygotowania i normalizacji metadanych obrazów
                RM płuc
              </strong>
              oraz
              <strong>
                Pracownik ds. przygotowania i normalizacji metadanych obrazów RM
                serca</strong
              >.
            </v-card-subtitle>
            <v-card-text
              >Informacje na temat wyników znajdują się w poniższych
              dokumentach:</v-card-text
            >
            <v-card-text
              ><a
                href="../data/Wyniki_postępowania_Pracownik_ds_przygotowania_i_normalizacji_metadanych_obrazów_RM_serca_13052022.pdf"
                target="_blank"
                ><strong
                  ><v-btn class="ma-2" color="indigo" dark
                    ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                  >
                  Wyniki postępowania Pracownik ds. przygotowania i normalizacji
                  metadanych obrazów RM serca - 13052022</strong
                >
              </a></v-card-text
            >

            <v-card-text
              ><a
                href="../data/Wyniki_postępowania_Pracownik_ds_przygotowania_i_normalizacji_metadanych_obrazów_RM_płuc_13052022.pdf"
                target="_blank"
                ><strong
                  ><v-btn class="ma-2" color="indigo" dark
                    ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                  >
                  Wyniki postępowania Pracownik ds. przygotowania i normalizacji
                  metadanych obrazów RM płuc - 13052022</strong
                >
              </a></v-card-text
            >
          </v-card>
          <v-card class="mx-auto pa-8 mb-8" max-width="800" elevation="2">
            <v-card-title>Zapytanie ofertowe</v-card-title>

            <v-card-subtitle>
              W związku z realizacją przez
              <strong> AI SYNESTHESIA Sp. z o.o. </strong> Projektu p.n. „COVID
              FAST: Ocena zmian w miąższu płucnym występujących u pacjentów po
              przebytej infekcji COVID-19 i ich wpływ na krążenie płucne -
              badanie przy użyciu autorskiego, hybrydowego protokołu opartego o
              RM, USG i algorytmy sztucznej inteligencji.” finansowanego ze
              środków Narodowego Centrum Badań i Rozwoju w ramach Strategicznego
              Programu Badań Naukowych i Prac Rozwojowych „Zaawansowane
              technologie informacyjne, telekomunikacyjne i mechatroniczne” –
              INFOSTRATEG zapraszamy do złożenia oferty na stanowisko Pracownika
              ds. przygotowania i normalizacji metadanych obrazów RM serca.
            </v-card-subtitle>
            <v-card-text
              >Informacje na temat szczegółów zapytania oraz sposobu składania
              ofert znajdują się w dokumencie:</v-card-text
            >
            <v-card-text
              ><a
                href="../data/COVID_FAST_zapytanie_przyg_i_normaliz_metadanych_obrazów_RM_serca_20220430.pdf"
                target="_blank"
                ><strong
                  ><v-btn class="ma-2" color="indigo" dark
                    ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                  >
                  Zapytanie przygotowanie i normalizacja metadanych obrazów RM
                  serca</strong
                >
              </a></v-card-text
            >
          </v-card>
          <v-card class="mx-auto pa-8" max-width="800" elevation="2">
            <v-card-title>Zapytanie ofertowe</v-card-title>

            <v-card-subtitle>
              W związku z realizacją przez
              <strong> AI SYNESTHESIA Sp. z o.o. </strong> Projektu p.n. „COVID
              FAST: Ocena zmian w miąższu płucnym występujących u pacjentów po
              przebytej infekcji COVID-19 i ich wpływ na krążenie płucne -
              badanie przy użyciu autorskiego, hybrydowego protokołu opartego o
              RM, USG i algorytmy sztucznej inteligencji.” finansowanego ze
              środków Narodowego Centrum Badań i Rozwoju w ramach Strategicznego
              Programu Badań Naukowych i Prac Rozwojowych „Zaawansowane
              technologie informacyjne, telekomunikacyjne i mechatroniczne” –
              INFOSTRATEG zapraszamy do złożenia oferty na stanowisko Pracownika
              ds. przygotowania i normalizacji metadanych obrazów RM płuc.
            </v-card-subtitle>
            <v-card-text
              >Informacje na temat szczegółów zapytania oraz sposobu składania
              ofert znajdują się w dokumencie:</v-card-text
            >
            <v-card-text>
              <a
                href="../data/COVID_FAST_zapytanie_przyg_i_normaliz_metadanych_obrazów_RM_pluc_20220430.pdf"
                target="_blank"
              >
                <v-btn class="ma-2" color="indigo" dark
                  ><v-icon dark>mdi-file-pdf-box</v-icon></v-btn
                ><strong
                  >Zapytanie przygotowanie i normalizacja metadanych obrazów RM
                  płuc</strong
                >
              </a></v-card-text
            >
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import HeroAlt from "../components/HeroAlt.vue";
import news from "../assets/news.jpg";
export default {
  components: {
    HeroAlt,
  },
  data() {
    return {
      heroAlt: [
        {
          src: news,
          heading: " Aktualności",
        },
      ],
      items: [
        {
          title: "ekspert ds. metodologii badawczych",
          subtitle:
            "szczegółowy opis zamówienia znajduje się w załączniku nr 1",
        },
        {
          title:
            "pracownik badawczy o profilu IT, senior programista - ekspert AI",
          subtitle:
            "szczegółowy opis zamówienia znajduje się w załączniku nr 2",
        },
        {
          title: "pracownik badawczy o profilu IT, analityk systemowy",
          subtitle:
            "szczegółowy opis zamówienia znajduje się w załączniku nr 3",
        },
        {
          title: "pracownik badawczy - programista Interfejsu użytkownika",
          subtitle:
            "szczegółowy opis zamówienia znajduje się w załączniku nr 4",
        },
        {
          title: "ekspert ds. metodologii wywiadu medycznego",
          subtitle:
            "szczegółowy opis zamówienia znajduje się w załączniku nr 5",
        },
        {
          title: "pracownik badawczy, specjalista",
          subtitle:
            "szczegółowy opis zamówienia znajduje się w załączniku nr 6",
        },
        {
          title: "pracownik badawczy - lekarz medycyny, ekspert",
          subtitle:
            "szczegółowy opis zamówienia znajduje się w załączniku nr 7",
        },
        {
          title: "pracownik badawczy ekspert ewaluator",
          subtitle:
            "szczegółowy opis zamówienia znajduje się w załączniku nr 8",
        },
        {
          title: "pracownik badawczy anotator- analityk 1",
          subtitle:
            "szczegółowy opis zamówienia znajduje się w załączniku nr 9",
        },
        {
          title: "pracownik badawczy anotator- analityk 2",
          subtitle:
            "szczegółowy opis zamówienia znajduje się w załączniku nr 10",
        },
        {
          title:
            "pracownik badawczy - inżynier interfejsu, tester rozwiązań UX",
          subtitle:
            "szczegółowy opis zamówienia znajduje się w załączniku nr 11",
        },
        {
          title: "tester bug-fix",
          subtitle:
            "szczegółowy opis zamówienia znajduje się w załączniku nr 12",
        },
      ],
    };
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
#footer-top {
  display: none !important;
}
</style>
