<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12">
        <HeroAlt :heroAlt="heroAlt" />
        <v-container>
          <v-row class="py-16">
            <v-col cols="12">
              <h3
                class="text-h4 text-uppercase font-weight-thin text-center my-8"
              >
                CovidFast to innowacyjne narzędzie dla lekarzy do oceny stanu płuc i serca u pacjentów z powikłaniami po przebyciu COVID-19
              </h3>
            </v-col>
              </v-row>
        </v-container>
        <v-row no-gutters>
          <v-col cols="12" md="6" align-self="center">
            <v-img
              max-height="1000"
              src="../assets/covid_fast_5.jpg"
              lazy-src="../assets/covid_fast_1.jpg"
            >
            </v-img>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <div class="pa-lg-16 pa-md-10 px-4 py-8">
              <h2 class="text-h3 text-center font-weight-black">Dla Kogo?</h2>
              <h3
                class="text-h5 text-uppercase font-weight-thin text-center my-8"
              >
              </h3>
              <p>
             Autorski protokół badania rezonansu magnetycznego COVID-FAST jest przeznaczony dla lekarzy leczących pacjentów chorych na nadciśnienie i zaburzenia lipidowe, zarówno w szpitalnych oddziałach kardiologicznych, jak i w poradniach specjalistycznych. Dzięki zastosowaniu algorytmów sztucznej inteligencji narzędzie będzie wspierać lekarzy w wykrywaniu zmian zarówno w zakresie miąższu płucnego, jak i wtórnych zmian w mięśniu sercowym w wyniku przeciążenia krążenia sercowo-płucnego.
Dedykowana aplikacja umożliwia w krótkim czasie (poniżej 15 minut), w bezpieczny sposób (brak promieniowania), z dużą dokładnością i wszechstronnie ocenić rozległość powikłań po infekcji COVID-19.  
Odbiorcami rozwiązań, które planujemy stworzyć, są podmioty lecznicze wyposażone w instalację rezonansu magnetycznego.
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="flex-row-reverse">
          <v-col cols="12" md="6" align-self="center">
            <v-img
              max-height="1000"
              src="../assets/covid_fast_2.jpg"
              lazy-src="../assets/covid_fast_2.jpg"
            >
            </v-img>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <div class="pa-lg-16 pa-md-10 px-4 py-16">
              <h2 class="text-h3 text-center font-weight-black">Zastosowanie</h2>
              <h3
                class="text-h5 text-uppercase font-weight-thin text-center my-8"
              >
              </h3>
              <p>
              Powikłania po chorobie COVID-19 dotyczą wielu narządów i mogą pozostawiać w nich długotrwałe zmiany. Jednymi z najczęstszych powikłań są zmiany w płucach (zwłóknienia/zrosty), które wiążą się z upośledzeniem perfuzji płuc, prowadzą do przeciążenia łożyska płucnego, co powoduje upośledzenie struktury i funkcji prawej komory serca.
              </p>
            </div>
          </v-col>
        </v-row>
  <v-row no-gutters>
          <v-col cols="12" md="6" align-self="center">
            <v-img
              max-height="1000"
              src="../assets/covid_fast_3.jpg"
              lazy-src="../assets/covid_fast_3.jpg"
            >
            </v-img>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <div class="pa-lg-16 pa-md-10 px-4 py-16">
              <h2 class="text-h3 text-center font-weight-black">Zalety</h2>
            <h3
                class="text-h5 text-uppercase font-weight-thin text-center my-8"
              >
              </h3>
           <v-list>
               <v-list-item-group>
                   <v-list-item
                        v-for="(item, i) in items"
                        :key="i"
                        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-text class="list-item">{{ item.text }}</v-list-item-text>
          </v-list-item-content>
        </v-list-item>
               </v-list-item-group>
           </v-list>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="flex-row-reverse">
          <v-col cols="12" md="6" align-self="center">
            <v-img
              max-height="1000"
              src="../assets/covid_fast_4.jpg"
              lazy-src="../assets/covid_fast_4.jpg"
            >
            </v-img>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <div class="pa-lg-16 pa-md-10 px-4 py-16">
              <h2 class="text-h3 text-center font-weight-black">Grupa docelowa</h2>
            <h3
                class="text-h5 text-uppercase font-weight-thin text-center my-8"
              >
              </h3>
           <v-list>
               <v-list-item-group>
                   <v-list-item
                        v-for="(item, i) in items2"
                        :key="i"
                        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-text class="list-item" >{{ item.text }}</v-list-item-text>
          </v-list-item-content>
        </v-list-item>
               </v-list-item-group>
           </v-list>
            </div>
          </v-col>
        </v-row>
      <v-row no-gutters>
          <v-col cols="12" md="6" align-self="center">
            <v-img
              max-height="800"
              src="../assets/covid_fast_7.jpg"
              lazy-src="../assets/covid_fast_7.jpg"
            >
            </v-img>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <div class="pa-lg-16 pa-md-10 px-4 py-16">
              <h2 class="text-h3 text-center font-weight-black">Opis proptotypu</h2>
              <h3
                class="text-h5 text-uppercase font-weight-thin text-center my-8"
              >
              </h3>
              <p>
             Głównym rezultatem projektu będzie stworzenie oprogramowania, które będzie w stanie w sposób automatyczny wspomagać lekarza w wykrywaniu powikłań po infekcji COVID-19 w płucach i sercu.
Jego działanie będzie oparte o autorski protokół badania COVID-FAST, czyli szybką (ok. 10 min) ocenę powikłań w RM płuc i serca. Dodatkowo, celem weryfikacji podejrzanych zmian, na obrazy uzyskane z RM będą nakładane obrazy z USG serca (echo). Algorytmy sztucznej inteligencji nie tylko wskażą patologię i ocenią ich wpływ na przeciążenie naczyniowego łożyska płucnego, ale również pozwolą oszacować ich wpływ na funkcję płuc, ponieważ w zakres danych treningowych będą wchodziły również wyniki badania pletyzmograficznego z oceną DLCO, które pozwalają ocenić czynnościową restrykcję miąższu płucnego.

              </p>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="flex-row-reverse">
          <v-col cols="12" md="6" align-self="center">
            <v-img
              max-height="800"
              src="../assets/covid_fast_6.jpg"
              lazy-src="../assets/covid_fast_6.jpg"
            >
            </v-img>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <div class="pa-lg-16 pa-md-10 px-4 py-16">
              <h2 class="text-h3 text-center font-weight-black">Plan Rozwoju</h2>
              <h3
                class="text-h5 text-uppercase font-weight-thin text-center my-8"
              >
              </h3>
              <p>
             W wyniku projektu dofinansowanego w ramach programu INFOSTRATEG I, pt. „Ocena zmian w miąższu płucnym występujących u pacjentów po przebytej infekcji COVID-19 i ich wpływ na krążenie płucne - badanie przy użyciu autorskiego, hybrydowego protokołu opartego o RM, USG i algorytmy sztucznej inteligencji.” powstanie produkt gotowy do wdrożenia na rynek 
Produkt będzie oferowany podmiotom dysponującym urządzeniem do rezonansu płuc, natomiast aplikacja będzie instalowana na sprzęcie służącym do wykonywania rezonansu - USG - echo serca lub w trybie zdalnym, po rejestracji w serwisie internetowym dostawcy.
              </p>
              <p>
Produktem towarzyszącym, dystrybuowanym jako dodatek, będzie aplikacja umożliwiająca nakładanie obrazu z RM na obraz z badania ECHO SERCA, w celu zwiększenia dokładności badania i pogłębienia diagnozy. 
</p>
<p>
Kolejnym krokiem będzie rozszerzenie rynku odbiorców na rynek europejski i międzynarodowe.

              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <UESection />
  </section>
  
</template>

<script>
import HeroAlt from "../components/HeroAlt.vue"
import projects from "../assets/projects.jpg"
import UESection from "../components/UESection.vue"
export default {
components: {
    HeroAlt,
    UESection
},
  data() {
    return {
      heroAlt: [
        {
          src: projects,
          heading: 'CovidFast ',
        },
      ],
      items: [
          {
          icon: 'mdi-check',
          text: 'Nieosiągalny dotychczas poziom dokładności i kompleksowości oceny układu płucno-sercowego pacjenta'
          },
          {
          icon: 'mdi-check',
          text: 'Innowacyjne oprogramowanie, działające w oparciu o nowe algorytmy informatyczne'
          },
           {
          icon: 'mdi-check',
          text: 'Skrócenie czasu oczekiwania na wynik badania'
          },
        {
          icon: 'mdi-check',
          text: 'większa liczba badań przyczyni się do podniesiania dostępności badań'
          },
      ],
         items2: [
          {
          icon: 'mdi-check',
          text: 'Jednostki ochrony zdrowia i przedsiębiorstwa świadczące usługi w tym zakresie'
          },
          {
          icon: 'mdi-check',
          text: 'Szpitale publiczne i stosunkowo duże przedsiębiorstwa świadczące usługi diagnostyczne'
          },
           {
          icon: 'mdi-check',
          text: 'Jednostki medyczne zajmujące się pacjentami po przebytej infekcji COVID, które będą mogły używać naszego produktu w formie aplikacji połączonej z systemem PACS'
          },
        {
          icon: 'mdi-check',
          text: 'Producenci i dystrybutorzy sprzętu do diagnostyki obrazowej'
          },
      ]
    }
  },
}
</script>
<style lang="scss" scoped>

.v-list-item__content
.list-item {
    color: rgba(0, 0, 0, 0.87);
    line-height: 1,2rem !important
}
</style>