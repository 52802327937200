

<template>
  <section
    
  >
   
  <v-footer
    class="footer"
    padless
  >
    <v-card
      class="flex footer"
      tile
      elevation="0"

    >
      <v-card-text class=" footer  darken-4--text text-center px-10">
       Projekt dofinansowano ze środków budżetu Państwa Strategiczny Program Badań Naukowych i Prac Rozwojowych <br> Covid Fast: Ocena zmian w miąższu płucnym występujących u pacjentów po przebytej infekcji COVID-19 i ich wpływ na krążenie płucne - badanie przy użyciu autorskiego, hybrydowego
       protokołu opartego o RM, USG i algorytmy sztucznej inteligencji.
      </v-card-text>
      <v-card-text class="footer darken-4--text text-center pt-0 px-10">
        <span class="text-uppercase">dofinansowanie</span>: 8 167 926,00 zł <br>
         <span class="text-uppercase">całkowita wartość</span>: 9 465 870,00 zł
      </v-card-text>
      <v-card-text class="py-2 white--text text-center">
     <v-row class="footer-flags">
        <v-col cols="3">
         <div></div>
       </v-col>
       <v-col cols="3">
         <div class="flags-img-right"></div>
       </v-col>
       <v-col cols="3">
         <div class="flags-img-left"></div>
       </v-col>
        <v-col cols="3">
         <div></div>
        </v-col>
     </v-row>
      </v-card-text>
    </v-card>
  </v-footer>
  
  </section>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      
    }
  },
}
</script>
<style>
.footer {
  /* background-color: #2C3E50; */
  background-color: #EAECED;
}
.footer-flags {
  padding: 20px;
  border: none;
}
.flags-img-right {
  height: 80px;
  background: url('../assets/flaga_polska.png') bottom center no-repeat;
  background-size: contain;
}
.flags-img-left {
  height: 80px;
  background: url('../assets/godlo_polski.png') bottom center no-repeat;
  background-size: contain;
}
</style>












